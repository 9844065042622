figure {
	position: relative; // Used together with image url

	&.figure {
		// Left aligned if max-width is set
		&-default {
			margin: 0;

			> img {
				width: 100%; // In case the originalt image width is smaller than its parent
				height: auto;
			}
		}

		// Center if max-width is set
		&-center {
			margin: 0 auto;

			> img {
				width: 100%; // In case the originalt image width is smaller than its parent
				height: auto;
			}
		}

		// Height constaints
		&-height {
			margin: 0;

			> img {
				width: auto;
				height: 100%;
				margin: auto;
			}
		}
	}

	// Background image instead of figure > img
	&.figure-background-image {
		margin: 0;
	}

	// Background image is an anchor inside a figure elem
	.figure-background-image {
		display: block;
		transition: opacity $time;

		&:hover {
			opacity: $opacity;
		}
	}

	// Figure > img with an anchor inside the figure elem
	.figure-anchor {
		position: absolute;
		z-index: 1; // Prevent flickering
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		text-decoration: none;

		+ img {
			transition: opacity $time;
		}

		&:hover + img {
			opacity: $opacity;
		}
	}

	> img {
		display: block; // Makes the img take up real space
	}

	> figcaption {
		@include type('t6');
		font-style: italic;
		padding-top: rem($t);
	}
}

// Genereal background image styling
.figure-background-image {
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;

	@media #{$small-max} {
		height: rem(200);
	}

	@media #{$med-large} {
		height: rem(250);
	}

	@media #{$large} {
		height: rem(320);
	}
}
