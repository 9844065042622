$timer-height: 8;

.timer {
	margin: rem((24 - $timer-height) / 2) 0;
	height: rem($timer-height);
	border-radius: rem($timer-height / 2);
	background-color: $bright;

	&-bar {
		height: 100%;
		border-radius: rem($timer-height / 2);
		background-color: $prim;
	}
}
