.video {
	&-wrap {
		position: relative;
		height: 0;

		&:not([style*='padding-bottom']) {
			padding-bottom: 56.25%; // 16:9
		}

		> * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
