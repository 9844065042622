button.card {
	@include appearance;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
	cursor: pointer;
	border: none;
	padding: 0;
	padding-bottom: rem($s + $bw-med);

	height: 100%;

	// Resets
	color: $bright;
	text-decoration: none;

	&:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: rem($bw-med);
		background-color: $prim;
		transition: right $time;
	}

	&:hover {
		&:after {
			right: rem($m);
		}

		.card-title {
			color: $prim;
		}

		.card-row {
			opacity: $opacity;
		}
	}
}

div.card .card-title {
	color: $prim;
}

.card {
	background-color: rgba($dark, 0.1);

	&-title {
		@include type('t3');
		text-transform: uppercase;
		margin: 0;
		transition: color $time;
	}

	&-row {
		display: flex;
		transition: opacity $time;
	}

	&-col {
		@include type('t4');

		&:first-child {
			color: $prim;
			width: rem(60);
		}

		&:last-child {
			margin-left: rem($t);
		}
	}
}
