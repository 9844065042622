/**
 * The width-class on an element, will determine the max-width of that element.
 */

.width {
	&-l {
		@include width('l');

		&-nested {
			@include width($type: 'l', $nested: true);
		}

		&-max {
			@include width($type: 'l', $max: true);
		}
	}

	&-m {
		@include width('m');

		&-nested {
			@include width($type: 'm', $nested: true);
		}

		&-max {
			@include width($type: 'm', $max: true);
		}
	}

	&-s {
		@include width('s');

		&-nested {
			@include width($type: 's', $nested: true);
		}

		&-max {
			@include width($type: 's', $max: true);
		}
	}
}
