// Based on modal
.comp {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
	overflow-y: auto;
	text-align: center;

	-webkit-overflow-scrolling: touch;

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		// margin-right: rem(-4); // Not sure if this is necessary
	}

	> * {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		width: 100%;
	}

	&-body {
		// margin-left: rem(4); // Not sure if this is necessary
		@include spacing('m');
	}
}

/*

// <div class="comp-vh-old [bg-dark]">

//   <div [class="bg-img-dark"]>The comp-vh-old-[top/center/bottom] can have a prev sibling element</div>

//   <div class="comp-vh-old-[top/center/bottom]">
//     <div>Your markup/content. Do not replace or add anything to the non-class div wrapping your markup/content.<div>
//   </div>
// </div>

@mixin comp-vh-old($min-height: true) {
	display: flex; // If you have items within the child, you'll have to display flex
	flex-direction: row; // flex-direction reset
	width: 100%; // Since the parent flex-direction is row, we've to specify a width
	height: 100%; // Part of the "2x scroll (huge scroll)" fix. See _box.scss for more information
	min-height: 100vh;

	> * {
		width: 100%; // Since the parent flex-direction is row, we've to specify a width
		min-height: rem(1); // IE 11 workaround when the inner child thinks it's higher than it actually is
	}
}

.comp-old {
	// 100% WH
	&-vh {
		position: relative; // Works together with the bg-img logic
		display: flex;
		flex-direction: row; // By having a parent with flex-direction row, the min-height bug in IE doesn't stick around

		&-top,
		&-top-right {
			@include comp-vh-old;
		}

		&-center {
			@include comp-vh-old;
			align-items: center;
		}

		&-bottom,
		&-bottom-right {
			@include comp-vh-old;
			align-items: flex-end;
		}

		// If a child is a part of the bg- [img/video] logic
		> *[class^='bg-'] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			// Due to the positioning of the bg-img element, siblings must på relative to reset DOM order
			~ * {
				position: relative;
				z-index: 1;
			}
		}
	}

	&-hz {
		@media #{$med} {
			overflow: hidden;
		}

		&-wrap {
			@media #{$small-max} {
				width: 100% !important; // JS inline overwrite
				padding-right: 0 !important; // JS inline overwrite
			}

			@media #{$med} {
				display: flex;
			}

			> * {
				@media #{$small-max} {
					width: 100% !important; // JS inline overwrite
				}

				@media #{$med} {
					padding-right: rem($h);
				}
			}
		}
	}

	&-sticky {
		position: relative;

		> * {
			position: sticky;
			top: 0;
			height: 100vh;

			> *[class^='bg-img'] {
				height: 100%;

				// Due to the positioning of the bg-img element, siblings must på relative to reset DOM order
				+ * {
					position: relative;
				}
			}
		}
	}
}

*/
