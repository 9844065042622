@mixin editor {
	// Shared styling
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $prim;
		text-transform: uppercase;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	ul,
	ol {
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	li {
		> a {
			@include type-anchor;
		}
	}

	p {
		// This can in theory happen, but we've disabled the media upload button within the WYSIWYG field (WP only)
		> img {
			display: block;
			height: auto;

			// WP inspired styles
			&.alignnone {
				width: 100%;
			}

			&.aligncenter {
				margin-right: auto;
				margin-left: auto;
			}

			&.alignright {
				margin-left: auto;
			}

			&.alignleft {
				margin-right: auto;
			}

			&.aligncenter,
			&.alignright,
			&.alignleft {
				max-width: 100%;
			}
		}
	}

	> ul,
	> ol {
		display: inline-block; // Prevent text-align center and floating images from moving the dot to far away from the sentence
		text-align: left; // Event though the ul/ol is aligned, the list bullet and text should stick together
		font-size: 16px; // Reset in case of nesting
	}

	ul,
	ol {
		list-style: none;
		padding-left: 0;

		> li {
			position: relative;

			&:before {
				position: absolute;
				left: 0;
			}
		}
	}

	ul > li:before {
		content: '';
		background-color: $prim;
	}

	ol {
		counter-reset: ol;

		> li:before {
			content: counters(ol, '.') ' ';
			counter-increment: ol;
			color: $prim;
			text-align: right;
		}
	}

	strong > em,
	em > strong {
		font-style: normal; // We often do not have both bold and italic
	}

	// Simple table styling in case a tables is copy pased inside an editor
	> table {
		width: 100%;
		font-size: 16px; // Reset in case of nesting

		> tbody > tr:nth-child(2n + 2) {
			background-color: $gray;
		}
	}
}

.editor {
	@include editor;

	h1 {
		@include type('t1');

		&:not(:last-child) {
			margin-bottom: rem($s);
		}
	}

	h2 {
		@include type('t2');

		&:not(:last-child) {
			margin-bottom: rem($s);
		}
	}

	h3 {
		@include type('t3');

		&:not(:last-child) {
			margin-bottom: rem($s);
		}
	}

	h4 {
		@include type('t4');

		&:not(:last-child) {
			margin-bottom: rem($t);
		}
	}

	h5 {
		@include type('t5');

		&:not(:last-child) {
			margin-bottom: rem($t);
		}
	}

	h6 {
		@include type('t6');

		&:not(:last-child) {
			margin-bottom: rem($t);
		}
	}

	p {
		@include type('t5');

		&:not(:last-child) {
			margin-bottom: rem($t);
		}
	}

	$bullet-size-default: 8;
	$number-width-default: 22;

	> ul,
	> ol {
		&:not(:last-child) {
			margin-bottom: rem($s);
		}

		> li {
			@include type($size: 't5'); // Same as p
		}
	}

	ul > li {
		padding-left: rem($bullet-size-default + $t);

		&:before {
			width: rem($bullet-size-default);
			height: rem($bullet-size-default);

			// Remember to update the first hard coded value
			@media #{$med-max} {
				top: rem((26 - $bullet-size-default) / 2); // Same as 't5' lh
			}

			@media #{$large} {
				top: rem((30 - $bullet-size-default) / 2); // Same as 't5' lh
			}
		}
	}

	ol > li {
		padding-left: rem($number-width-default + $t);

		&:before {
			width: rem($number-width-default);
		}
	}

	> table > tbody > tr > td {
		@include type('t6');
		padding: rem($t);
		vertical-align: top;
	}

	// Small
	&-s {
		@include editor;

		p {
			@include type('t6');

			&:not(:last-child) {
				margin-bottom: rem($t);
			}
		}
	}

	// Large
	&-l {
		@include editor;

		p {
			@include type('t4');

			&:not(:last-child) {
				margin-bottom: rem($s);
			}
		}
	}

	// Bright
	&-bright {
		color: $bright;

		p > a {
			@include type-anchor($bright, $border-color: $bright);
		}
	}

	// Center
	&-center {
		text-align: center;
	}
}
