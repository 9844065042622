/**
 * Responsive JavaScript
 * https://adactio.com/journal/5429/
 */

body:after  {
	display: none;

	@media #{$small-max} {
		content: 'small';
	}

	@media #{$med-large} {
		content: 'med';
	}

	@media #{$large} {
		content: 'large';
	}
}

/**
 * JS example
 */

// var size = window.getComputedStyle(document.body,':after').getPropertyValue('content');
// if (size == 'small') {}
