.main {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	// Footer height
	padding-bottom: rem(64);

	background-image: url('../media/images/background.jpg');
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;

	> div {
		height: 100%;
		position: relative;
	}

	&-home {
		background-image: url('../media/images/team-spirit.jpg');

		&:before {
			background-color: rgba($dark, 0.7);
		}
	}

	&-cookies,
	&-gdpr {
		p {
			font-family: sans-serif;
		}
	}

	&:not(.main-home):before {
		background-color: rgba(#0d0a0e, 0.6);
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		~ * {
			position: relative;
			z-index: 1;
		}
	}
}
