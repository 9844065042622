/*
    This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/

@font-face {
	font-family: 'DIN W01 Cond Bold';
	src: url('../fonts/5611706/4878d18c-d1e1-4eaa-ad11-5a5ec726bbc1.eot?#iefix');
	src: url('../fonts/5611706/4878d18c-d1e1-4eaa-ad11-5a5ec726bbc1.eot?#iefix') format('eot'),
		url('../fonts/5611706/f9fb0794-9b57-4d8c-99f6-69d45d311f29.woff2') format('woff2'),
		url('../fonts/5611706/ac30a449-22f6-441a-a3cd-93251696660e.woff') format('woff'),
		url('../fonts/5611706/1c234575-4907-46fb-a87c-4ab80464bb84.ttf') format('truetype');
}
