@mixin cf {
	*zoom: 1;

	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin appearance {
	// There is no appearance, only -webkit- and -moz-
	-webkit-appearance: none;
	-moz-appearance: none;
}

// https://medium.com/buddy-reno/using-sass-at-root-for-nesting-variants-874735eb5766
@mixin root($selector) {
	@at-root #{$selector}#{&} {
		@content;
	}
}

@mixin col-core($small-max-breakpoint: $small-max, $med-breakpoint: $med, $gutter: $m) {
	@media #{$med-breakpoint} {
		display: flex;
		flex-wrap: wrap;
		// align-items: flex-start;
		min-height: rem(1); // Prevent colapsing cells

		&.col-center {
			justify-content: center;
		}

		&.col-middle {
			align-items: center;
		}
	}

	> * {
		@include color-check;

		@media #{$small-max-breakpoint} {
			@include col(1, $gutter: $gutter); // This will only give a margin-top effect
		}

		@media #{$med-breakpoint} {
			min-height: rem(1); // Prevent colapsing cells
		}
	}
}

@mixin col-border($dir, $pos) {
	@include root('.col-border') {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			width: rem(1);
			height: 100%;
			background-color: $gray;

			@if $pos == 0 {
				#{$dir}: $pos; // No need for a unit
			} @else {
				#{$dir}: rem(round($pos));
			}
		}
	}
}

@mixin col($end, $gutter: $m, $margin: $gutter, $width: true) {
	@if $end == 5 {
		@if $width {
			width: 20%;
		}
	} @else if $end == 4 {
		@if $width {
			width: 25%;
		}
	} @else if $end == 3 {
		@if $width {
			// Even though it's supportet, calc() is not 100% accurate in IE and can break the grid entirely
			width: 33.333333333%;
		}
	} @else if $end == 2 {
		@if $width {
			width: 50%;
		}
	}

	@for $i from 1 through $end {
		&:nth-child(#{$end}n + #{$i}):not(:nth-child(#{$i})) {
			margin-top: rem($margin);
		}

		@if $end == 5 {
			@if $i == 1 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('right', ($gutter / 3));
					padding-right: rem($gutter);
				}
			} @else if $i == 2 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('right', 0);
					padding-right: rem($gutter / 3 * 2);
					padding-left: rem($gutter / 3);
				}
			} @else if $i == 3 {
				&:nth-child(#{$end}n + #{$i}) {
					padding-right: rem($gutter / 3);
					padding-left: rem($gutter / 3 * 2);
				}
			} @else if $i == 4 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('left', ($gutter / 3));
					padding-left: rem($gutter / 3 * 2);
					padding-right: rem($gutter / 3);
				}
			} @else if $i == 5 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('left', ($gutter / 3));
					padding-left: rem($gutter);
				}
			}
		} @else if $end == 4 {
			@if $i == 1 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('right', ($gutter / 3));
					padding-right: rem($gutter);
				}
			} @else if $i == 2 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('right', 0);
					padding-right: rem($gutter / 3 * 2);
					padding-left: rem($gutter / 3);
				}
			} @else if $i == 3 {
				&:nth-child(#{$end}n + #{$i}) {
					padding-right: rem($gutter / 3);
					padding-left: rem($gutter / 3 * 2);
				}
			} @else if $i == 4 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('left', ($gutter / 3));
					padding-left: rem($gutter);
				}
			}
		} @else if $end == 3 {
			@if $i == 1 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('right', (($gutter / $end) / 2));
					padding-right: rem($gutter / $end * 2);
				}
			} @else if $i == 2 {
				&:nth-child(#{$end}n + #{$i}) {
					padding-right: rem($gutter / $end);
					padding-left: rem($gutter / $end);
				}
			} @else if $i == 3 {
				&:nth-child(#{$end}n + #{$i}) {
					@include col-border('left', (($gutter / $end) / 2));
					padding-left: rem($gutter / $end * 2);
				}
			}
		} @else if $end == 2 {
			// In some edge cases, it's possible to end up with only one child in a 2 col logic.
			// We therefor have an extra check for an only-child situastion.
			@if $i == 1 {
				&:nth-child(#{$end}n + #{$i}):not(:only-child) {
					@include col-border('right', 0);
					padding-right: rem($gutter / $end);
				}
			} @else if $i == 2 {
				&:nth-child(#{$end}n + #{$i}):not(:only-child) {
					padding-left: rem($gutter / $end);
				}
			}
		}
	}
}

// Used in _blocks, but also once in _col
@mixin spacing-check($margin: true, $padding: true) {
	@if $margin {
		// No need for spacing since the container div element is (should be) present.
		&[style],
		&[class*='block-gradient'] {
			// The div specification gives us better priority,
			// which prevents us from using !important.
			+ div[style],
			+ div[class*='block-gradient'] {
				margin-top: 0;
			}
		}
	}

	@if $padding {
		// First and last child of the .main class.
		// Better than a container div since this is conditional.
		.main > & {
			&:first-child:not([style]) {
				padding-top: rem($l);
			}

			&:last-child:not([style]) {
				padding-bottom: rem($l);
			}
		}
	}
}

// Used in _blocks and 2 times in this document
@mixin color-check {
	&[style*='background-color'] {
		&[style*='rgb(255, 255, 255)'] {
			color: $dark;

			// p > a {
			// 	@include type-anchor($color: $prim, $border-color: $dark);
			// }
		}
	}

	&[style*='background-color'] {
		&[style*='rgb(13, 10, 14)'] {
			color: $bright;

			// p > a {
			// 	@include type-anchor($color: $prim, $border-color: $prim);
			// }
		}
	}
}

@mixin spacing($size, $prop: 'margin') {
	// Tiny
	@if $size == 't' {
		#{$prop}-top: rem($t);
		#{$prop}-bottom: rem($t);
	} @else if $size == 'tt' {
		#{$prop}: rem($t);
	} @else if $size == 't-top' {
		#{$prop}-top: rem($t);
	} @else if $size == 't-bottom' {
		#{$prop}-bottom: rem($t);

		// Small
	} @else if $size == 's' {
		#{$prop}-top: rem($s);
		#{$prop}-bottom: rem($s);
	} @else if $size == 'ss' {
		#{$prop}: rem($s);
	} @else if $size == 's-top' {
		#{$prop}-top: rem($s);
	} @else if $size == 's-bottom' {
		#{$prop}-bottom: rem($s);

		// Medium
	} @else if $size == 'm' {
		@media #{$med-max} {
			#{$prop}-top: rem($s);
			#{$prop}-bottom: rem($s);
		}

		@media #{$large} {
			#{$prop}-top: rem($m);
			#{$prop}-bottom: rem($m);
		}
	} @else if $size == 'mm' {
		@media #{$med-max} {
			#{$prop}: rem($s);
		}

		@media #{$large} {
			#{$prop}: rem($m);
		}
	} @else if $size == 'm-top' {
		@media #{$med-max} {
			#{$prop}-top: rem($s);
		}

		@media #{$large} {
			#{$prop}-top: rem($m);
		}
	} @else if $size == 'm-bottom' {
		@media #{$med-max} {
			#{$prop}-bottom: rem($s);
		}

		@media #{$large} {
			#{$prop}-bottom: rem($m);
		}

		// Large
	} @else if $size == 'l' {
		@media #{$med-max} {
			#{$prop}-top: rem($m);
			#{$prop}-bottom: rem($m);
		}

		@media #{$large} {
			#{$prop}-top: rem($l);
			#{$prop}-bottom: rem($l);
		}
	} @else if $size == 'll' {
		@media #{$med-max} {
			#{$prop}: rem($m);
		}

		@media #{$large} {
			#{$prop}: rem($l);
		}
	} @else if $size == 'l-top' {
		@media #{$med-max} {
			#{$prop}-top: rem($m);
		}

		@media #{$large} {
			#{$prop}-top: rem($l);
		}
	} @else if $size == 'l-bottom' {
		@media #{$med-max} {
			#{$prop}-bottom: rem($m);
		}

		@media #{$large} {
			#{$prop}-bottom: rem($l);
		}

		// Huge
	} @else if $size == 'h' {
		@media #{$small-max} {
			#{$prop}-top: rem($m);
			#{$prop}-bottom: rem($m);
		}

		@media #{$med-large} {
			#{$prop}-top: rem($l);
			#{$prop}-bottom: rem($l);
		}

		@media #{$large} {
			#{$prop}-top: rem($h);
			#{$prop}-bottom: rem($h);
		}
	} @else if $size == 'hh' {
		@media #{$small-max} {
			#{$prop}: rem($m);
		}

		@media #{$med-large} {
			#{$prop}: rem($l);
		}

		@media #{$large} {
			#{$prop}: rem($h);
		}
	} @else if $size == 'h-top' {
		@media #{$small-max} {
			#{$prop}-top: rem($m);
		}

		@media #{$med-large} {
			#{$prop}-top: rem($l);
		}

		@media #{$large} {
			#{$prop}-top: rem($h);
		}
	} @else if $size == 'h-bottom' {
		@media #{$small-max} {
			#{$prop}-bottom: rem($m);
		}

		@media #{$med-large} {
			#{$prop}-bottom: rem($l);
		}

		@media #{$large} {
			#{$prop}-bottom: rem($h);
		}
	} @else {
		@error 'The $size parameter is not provided.';
	}
}

@mixin list-reset {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

@mixin type($size) {
	@if $size == 't1' {
		@media #{$small-max} {
			font-size: em(36);
			line-height: em(42, 36);
		}

		@media #{$med-large} {
			font-size: em(60);
			line-height: em(70, 60);
		}

		@media #{$large} {
			font-size: em(90);
			line-height: em(100, 90);
		}
	} @else if $size == 't2' {
		@media #{$small-max} {
			font-size: em(30);
			line-height: em(40, 30);
		}

		@media #{$med-large} {
			font-size: em(36);
			line-height: em(42, 36);
		}

		@media #{$large} {
			font-size: em(60);
			line-height: em(70, 60);
		}
	} @else if $size == 't3' {
		@media #{$small-max} {
			font-size: em(24);
			line-height: em(30, 24);
		}

		@media #{$med} {
			font-size: em(32);
			line-height: em(40, 32);
		}
	} @else if $size == 't4' {
		@media #{$small-max} {
			font-size: em(18);
			line-height: em(26, 16);
		}

		@media #{$med} {
			font-size: em(20);
			line-height: em(28, 20);
		}
	} @else if $size == 't5' {
		@media #{$small-max} {
			font-size: em(18);
			line-height: em(26, 16);
		}

		@media #{$med} {
			font-size: em(20);
			line-height: em(28, 20);
		}
	} @else if $size == 't6' {
		font-size: em(16);
		line-height: em(24, 16);
	} @else {
		@error 'The $size parameter is not provided.';
	}
}

@mixin type-anchor($color: $prim, $border-color: $prim, $hover: $bright) {
	position: relative;
	color: $color;
	text-decoration: none;
	border-bottom-width: rem(1);
	border-bottom-style: solid;
	border-bottom-color: $border-color;
	transition: color $time, border-bottom-color $time;

	&:hover {
		color: $hover;
		border-bottom-color: transparent;
	}
}

@mixin width($type, $nested: false, $max: false) {
	@if not($max) {
		margin-right: auto;
		margin-left: auto;
	}

	@if not($nested) and not($max) {
		width: 93.75%;
	}

	@if $type == 'l' {
		max-width: rem($width-l);
	} @else if $type == 'm' {
		max-width: rem($width-m);
	} @else if $type == 's' {
		max-width: rem($width-s);
	}
}

@mixin svg {
	// Include svg() on the custom wrapping element,
	// where you – can – set a width/max-width and custom styling

	// Parent of inline svg element
	> * {
		position: relative;
		width: 100%;
		height: 0;
		// padding: ?; // Set the padding inline. This is just a reminder

		// The inline svg element
		> * {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
