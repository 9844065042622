/**
 * The container-class will create space both on top and bottom on an element.
 */

.container {
	&-overflow {
		overflow: hidden;
	}

	&-t {
		@include spacing('t', $prop: 'padding');

		&t {
			@include spacing('tt', $prop: 'padding');
		}

		&-top {
			@include spacing('t-top', $prop: 'padding');
		}

		&-bottom {
			@include spacing('t-bottom', $prop: 'padding');
		}
	}

	&-s {
		@include spacing('s', $prop: 'padding');

		&s {
			@include spacing('ss', $prop: 'padding');
		}

		&-top {
			@include spacing('s-top', $prop: 'padding');
		}

		&-bottom {
			@include spacing('s-bottom', $prop: 'padding');
		}
	}

	&-m {
		@include spacing('m', $prop: 'padding');

		&m {
			@include spacing('mm', $prop: 'padding');
		}

		&-top {
			@include spacing('m-top', $prop: 'padding');
		}

		&-bottom {
			@include spacing('m-bottom', $prop: 'padding');
		}
	}

	&-l {
		@include spacing('l', $prop: 'padding');

		&l {
			@include spacing('ll', $prop: 'padding');
		}

		&-top {
			@include spacing('l-top', $prop: 'padding');
		}

		&-bottom {
			@include spacing('l-bottom', $prop: 'padding');
		}
	}

	&-h {
		@include spacing('h', $prop: 'padding');

		&h {
			@include spacing('hh', $prop: 'padding');
		}

		&-top {
			@include spacing('h-top', $prop: 'padding');
		}

		&-bottom {
			@include spacing('h-bottom', $prop: 'padding');
		}
	}
}
