/**
 * The block class will, if there are siblings, create a space between elements.
 * We use a margin-top logic to better control spacing when a sibling logic is needed.
 * The logic is explained in the block-l section.
 */

@mixin block-checks($spacing-check, $color-check, $last-child-check) {
	@if $spacing-check {
		@include spacing-check;
	}

	@if $color-check {
		@include color-check;
	}

	@if $last-child-check {
		&:last-child[style*='border-bottom'] {
			border-bottom: none !important;
		}
	}
}

@mixin block-spacing($size) {
	@if $size == $m {
		@include spacing('m-top');
	} @else if $size == $l {
		@include spacing('l-top');
	} @else if $size == $h {
		@include spacing('h-top');
	} @else {
		margin-top: rem($size);
	}
}

@mixin block-engine($size, $spacing-check: true, $color-check: true, $last-child-check: true) {
	@include block-checks($spacing-check, $color-check, $last-child-check);

	// Generalt block logic
	&:not(:first-child) {
		@include block-spacing($size);
	}

	// Usually applied to images within an article
	&.block-inherit + [class^='block-'] {
		@include block-spacing($size);
	}

	// Keeps a statick spacing, no RWD logic
	&-static {
		@include block-checks($spacing-check, $color-check, $last-child-check);

		&:not(:first-child) {
			margin-top: rem($size);
		}

		// Usually applied to images within an article
		&.block-inherit + [class^='block-'] {
			margin-top: rem($size);
		}
	}
}

$block-sizes: (
	0: 0,
	t: $t,
	s: $s,
	m: $m,
	l: $l,
	h: $h
);

@each $key, $size in $block-sizes {
	.block-#{$key} {
		// Default
		@include block-engine($size);

		// In cases the block needs to be animated with JS, we ignore the default spacing check
		&-anim {
			@include block-engine($size, $spacing-check: false);
		}
	}
}
.block {
	&-gradient {
		&-a {
			@include gradient-a;
		}

		// &-b {
		// 	@include gradient-b;
		// }

		// &-c {
		// 	@include gradient-c;
		// }
	}
}
