@mixin btn-core {
	@include type('t5');
	position: relative; // Icons
	display: inline-block;
	padding-top: rem((60 - 4 - 30) / 2);
	padding-right: rem($m);
	padding-bottom: rem((60 - 4 - 30) / 2);
	padding-left: rem($m);
	border-width: rem($bw-med);
	border-style: solid;
	border-radius: 0;

	@include root('a') {
		text-decoration: none;
	}

	@include root('button') {
		@include appearance;
		cursor: pointer;
	}
}

.btn {
	&-prim {
		@include btn-core;
		color: $dark;
		font-weight: $bold;
		background-color: $prim;
		border-color: $prim;
		transition: background-color $time, color $time;

		&:disabled {
			background-color: $gray;
			border-color: $gray;
			color: rgba($dark, 0.5);
			cursor: not-allowed;
		}

		svg {
			fill: $dark;
		}

		&:not(:disabled):hover {
			background-color: transparent;
			color: $bright;

			.btn-meta-text {
				color: $bright;
			}
		}

		.btn-meta-text {
			color: $dark;
		}
	}

	&-sec {
		@include btn-core;
		color: $bright;
		font-weight: $bold;
		background-color: transparent;
		border-color: $prim;
		text-transform: uppercase;
		transition: background-color $time, color $time;

		&:disabled {
			border-color: $gray;
			color: $gray;
			cursor: not-allowed;
		}

		svg {
			fill: $bright;
		}

		&:not(:disabled):hover {
			background-color: $prim;
			color: $dark;

			.btn-meta-text {
				color: $dark;
			}
		}

		.btn-meta-text {
			color: $bright;
		}
	}

	&-tert {
		// Btn core edited
		@include type('t6');
		display: inline-block;
		padding: 0;
		border-bottom: rem(1) solid $prim;

		@include root('a') {
			text-decoration: none;
		}

		@include root('button') {
			@include appearance;
			cursor: pointer;
		}

		.no-touch &:hover {
			text-decoration: none;
		}

		color: $prim;
		font-weight: $bold;
		background-color: transparent;
		text-transform: uppercase;
		transition: border-color $time, color $time;

		svg {
			fill: $dark;
		}

		&:hover {
			color: #fff;
			border-color: #fff;
		}
	}

	// Text span inside .btn
	&-text {
		display: block; // Make sure the lh is taken in consideration when height is calc

		&:first-child:not(:last-child) {
			padding-right: rem(26 + 8);
		}

		&:last-child:not(:first-child) {
			padding-left: rem(26 + 8);
		}

		// Nested span to highlight extra text
		&-meta {
			display: inline-block;
			font-weight: $bold;
			margin-left: rem($s);
		}
	}

	// Icon span inside .btn
	&-icon {
		position: absolute;
		top: 50%;
		margin-top: rem(-13);
		width: rem(26);
		height: rem(26);

		&:first-child:not(:last-child) {
			left: rem($m);
		}

		&:last-child:not(:first-child) {
			right: rem($m);
		}
	}
}
