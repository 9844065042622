$input-height: 64;
$input-border-width: $bw-med;
$input-line-height: 28; // t5
$input-submit-width: $input-height;
$input-border-radius: 0;

.input {
	&-wrap {
		position: relative;
		overflow: hidden;

		display: flex;
		flex-wrap: wrap;

		> *:nth-last-child(2):first-child {
			order: 2;
		}

		> *:nth-child(2):last-child {
			order: 1;
			padding-bottom: rem($t);
		}
	}

	&-icon {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: rem(26);
		height: rem(26);
		background-image: url('../media/icons/search.svg');
	}

	&-field {
		@include type('t5');
		font-family: sans-serif;

		&:not([type='checkbox']) {
			@include appearance;
			width: 100%;
			min-height: rem($input-height); // This will prevent a textarea from shrinking if the user resizes the elem
			background-color: rgba($dark, 0);
			border-color: $prim;
			padding: rem(($input-height - $input-border-width * 2 - $input-line-height) / 2) rem($s);
			border-width: rem($input-border-width);
			border-style: solid;
			border-radius: rem($input-border-radius);
		}

		&[type='search'] {
			border-top: none;
			border-right: none;
			border-left: none;

			padding-left: rem($s + 26);
		}

		&[type='checkbox'] {
			@include appearance;
			visibility: hidden;

			position: absolute;
			top: rem(-1);
			left: rem(-1);
			width: rem(1);
			height: rem(1);

			&:checked + .input-field-clone:after {
				content: '';
				position: absolute;
				top: rem((22 - 10 - $bw-med * 2) / 2);
				left: rem((22 - 10 - $bw-med * 2) / 2);
				width: rem(10);
				height: rem(10);
				background-color: $prim;
				border-radius: 50%;
			}
		}

		@include root('select') {
			color: $gray;
		}

		&-clone {
			position: relative;
			width: rem(22);
			height: rem(22);
			margin-top: rem(4); // Optical middle
			border: rem($bw-med) solid $prim;

			+ .input-label {
				flex: 1 0 0px; // The 0px is for IE11
				padding-left: rem(12);
			}
		}
	}

	&-label {
		width: 100%;
		border-color: transparent;

		// There are only two elements in a noraml input + label
		&:nth-child(2) {
			@include type('t5');
			text-transform: uppercase;
		}

		// This will trigger if input-field-clone is present (checkbox)
		&:nth-child(3) {
			@include type('t6');
		}

		> a {
			@include type-anchor;
		}
	}

	&-field,
	&-label {
		color: $bright;
	}

	&-submit {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		width: rem($input-submit-width);
		height: 100%;
		background-color: $gray;
		border: none;
		border-radius: 0 rem($input-border-radius) rem($input-border-radius) 0;
		padding: 0;
		transition: background-color $time;

		svg {
			fill: $bright;
		}

		&[disabled] {
			opacity: $opacity;
			cursor: not-allowed;
		}

		&:not([disabled]):hover {
			background-color: $dark;
			cursor: pointer;

			svg {
				fill: $dark;
			}
		}
	}

	// This should be styled more interesting later
	// &-value .input-label {
	// 	display: none;
	// }

	&-success .input-field {
		color: $success;
		border-color: $success;
	}

	&-error .input-field {
		color: $error;
		border-color: $error;
	}
}

// Modern browsers
::placeholder {
	color: $gray;
	opacity: 1; // FF
}

// IE 10-11
:-ms-input-placeholder {
	color: $gray;
}

// Edge
::-ms-input-placeholder {
	color: $gray;
}
