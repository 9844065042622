// html.page-lock body > *:not(.box) {
// 	filter: blur(rem(5));
// }

.modal {
	display: none;
	overflow: hidden;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	background-color: rgba($dark, 0.7);

	-webkit-overflow-scrolling: touch;

	&-open {
		display: block;
		overflow-x: hidden;
		overflow-y: auto;
		text-align: center;
	}

	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		// margin-right: rem(-4);
	}

	> * {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		width: 100%;
	}

	&-body {
		@include width('s');
		@include spacing('l');
		@include spacing('mm', 'padding');
		position: relative; // modal-close
		border: rem($bw-med) solid $prim;
		background-color: $dark;
	}

	&-close {
		@include appearance;
		position: absolute;
		top: 0;
		right: 0;
		width: rem($m);
		height: rem($m);
		background-color: $prim;
		transition: background-color $time;

		// resets
		cursor: pointer;
		border: none;
		border-radius: 0;
		padding: 0;

		&:hover {
			background-color: #fff;

			&:before,
			&:after {
				transform: rotate(0);
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: rem(16);
			height: rem(2);
			margin-top: rem(-1);
			margin-left: rem(-8);
			background-color: #000;
			transition: transform $time;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}
}

/*

.modal-old {
	position: fixed;
	transition: all 0s $time; // This is actually the out-anim
	-webkit-overflow-scrolling: touch;

	&:not(.modal-transition) {
		overflow: auto;
	}

	&-transition {
		overflow: hidden !important; // Prevent an extra scroll bar from appearing when the elem is in transition, and a double jump on iOS when the modal is entering the screen
	}

	> * > * > *:not([class*='modal']) {
		opacity: 0;
		transform: translateY(100%);
		transition: opacity $time, transform $time; // Subtle in-anim
	}

	&:not(.is-visible) {
		z-index: -1;
		bottom: rem(-1);
		right: rem(-1);
		width: rem(1);
		height: rem(1);
		visibility: hidden;
	}

	&.is-visible {
		z-index: 100;
		top: 0;
		left: 0;
		width: 100%;
		overflow: auto; // This should be more specific when it comes to in-transition state

		// Part of the "2x scroll (huge scroll)" fix
		min-height: rem(1);
		height: 100%;
		max-height: 100vh; // This will be overwritten with JS

		// Transition reset since the in-anim should be posisioned immediately
		transition: none;

		> * > * > *:not([class*='modal']) {
			opacity: 1;
			transform: translateY(0);
		}

		.modal-background {
			opacity: 1;
		}
	}

	&-background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($dark, $opacity);
		opacity: 0;
		transition: opacity $time;
	}

	&-content {
		&-outer {
			@include width('m');
			// @include spacing($size: 'll', $prop: 'padding');
			position: relative; // Close button
			min-height: rem(1); // Part of the "2x scroll (huge scroll)" fix
			outline: none;

			// Same as spacing('ll') but with a custom twist due to the close button
			@media #{$med-max} {
				padding-top: rem($l);
				padding-block-end: rem($l);
			}

			@media #{$large} {
				padding: rem($l);
			}
		}

		&-inner {
			// ?
		}
	}

	&-close {
		@include appearance;
		position: absolute;
		top: 0;
		right: 0;
		background-color: transparent;
		padding: 0;
		border: none;
		width: rem($m);
		height: rem($m);

		&:hover {
			&:before,
			&:after {
				transform: translateX(-50%) rotate(0deg) !important;
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: rem(30);
			height: rem(4);
			border-radius: rem(2);
			background-color: $bright;
			transition: transform $time;
			will-change: transform;
		}

		&:before {
			transform: translateX(-50%) rotate(-45deg);
		}

		&:after {
			transform: translateX(-50%) rotate(45deg);
		}
	}
}

*/
