/**
 * Colors
 */

$bright: #fff; // Primary background color

$dark: #0d0a0e; // Primary text

$gray: #b6b6b6;

$prim: #38b8c3; // CTA
$prim-bright: #5bced8; // CTA details, eg. border

$success: #008833; // Joker green
$error: #d60812; // Joker red

// // https://css-tricks.com/css3-gradients/
@mixin gradient-a {
	color: $bright;
	background-image: linear-gradient(to bottom right, $prim, $prim-bright);
}

/**
 * Spacing
 */

$t: 10;
$s: 20;
$m: 30;
$l: 50;
$h: 100;

// Border width
$bw-small: 1;
$bw-med: 2;
$bw-large: 4;

// Border radius
$br-small: 2;
$br-med: 6;
$br-large: 10;

/**
 * Layout widths
 */

$width-l: 1200;
$width-m: 1000;
$width-s: 700;

/**
 * Font weights
 */

$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

/**
 * Animation
 */

// http://blog.percolatestudio.com/design/animation-timing-guidelines/
$time: 0.35s;
$opacity: 0.5;

/**
 * Breakpoints
 */

$tiny-max-screen: em(479);

$small-screen: em(480);
$small-max-screen: em(639);

$med-screen: em(640);
$med-max-screen: em(919);

$large-screen: em(920);
$large-max-screen: em(1199);

$huge-screen: em(1200);

$tiny-max: '(max-width:#{$tiny-max-screen})';

$small: '(min-width:#{$small-screen})';
$small-med: '(min-width:#{$small-screen}) and (max-width:#{$small-max-screen})';
$small-large: '(min-width:#{$small-screen}) and (max-width:#{$med-max-screen})';
$small-max: '(max-width:#{$small-max-screen})';

$med: '(min-width:#{$med-screen})';
$med-large: '(min-width:#{$med-screen}) and (max-width:#{$med-max-screen})';
$med-huge: '(min-width:#{$med-screen}) and (max-width:#{$large-max-screen})';
$med-max: '(max-width:#{$med-max-screen})';

$large: '(min-width:#{$large-screen})';
$large-huge: '(min-width:#{$large-screen}) and (max-width:#{$large-max-screen})';
$large-max: '(max-width:#{$large-max-screen})';

$huge: '(min-width:#{$huge-screen})';
