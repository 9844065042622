// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;

	&.page-lock {
		overflow-y: scroll;
		position: fixed;
		width: 100%;

		body {
			overflow: hidden;
			position: relative;
			height: 100%;
		}
	}
}

body {
	color: $bright;
	background-color: $dark;
	font-size: 1em;
	font-family: 'DIN W01 Cond Bold', sans-serif;
	// padding-top: rem(90); // Height of .menu

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
