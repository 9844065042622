@mixin layout-core {
	display: flex;
	min-height: rem(1); // Prevent colapsing cells
}

.layout {
	&-center {
		@include layout-core;
		justify-content: center;
	}

	&-middle {
		@include layout-core;
		align-items: center;
	}

	&-unknown {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: rem(-$t);

		> * {
			margin-right: rem($s);
			margin-bottom: rem($t);
		}
	}
}
