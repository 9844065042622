/**
 * Titles
 */

// t1 - t6
@for $i from 1 through 6 {
	.type-t#{$i} {
		@include type('t#{$i}');

		> a {
			@include type-anchor;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6  {
	&.type-light {
		font-weight: $light;
	}

	&.type-regular {
		font-weight: $regular;
	}

	&.type-semibold {
		font-weight: $semibold;
	}

	// NOTE: bold is default
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	&[class^='type-'] {
		margin-top: 0;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
	&.type-bright {
		color: $bright;
	}

	&.type-prim {
		color: $prim;
	}

	&.type-gray {
		color: $gray;
	}

	&.type-center {
		text-align: center;
	}

	&.type-right {
		text-align: right;
	}

	&.type-uppercase {
		text-transform: uppercase;
		letter-spacing: em(1);
	}

	@media #{$small-max} {
		&.type-br br {
			display: none;
		}
	}

	// NOTE: dark is default
}

div {
	&.type-light {
		font-weight: $light;
	}

	&.type-semibold {
		font-weight: $semibold;
	}

	&.type-bold {
		font-weight: $bold;
	}

	&.type-hide-text {
		position: relative;
		z-index: -1;
		visibility: hidden;
		overflow: hidden;
		white-space: nowrap;
		width: 0;
		height: 0;
		text-indent: rem(1);
	}

	// NOTE: regular is default
}

a,
input,
textarea,
select,
button[type='button'],
button[type='submit'] {
	outline: none;
}
