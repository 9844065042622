/*

 	Markup example of the col-logic

 	<div class="col-3">
 		<div>Your markup. Do not replace or modify the non-class div.</div>
		<div>Your markup. Do not replace or modify the non-class div.</div>
		<div>Your markup. Do not replace or modify the non-class div.</div>
 	</div>

	.col-3 {
		@include col-core;

		> * {
			// Since the col-core() starts at $med,
			// there's no need to have col() below that breakpoint
			@media #{$med-large} {
				@include col(2);
			}

			@media #{$large} {
				@include col(3);
			}
		}
	}

 */

.col {
	&-2 {
		// Regular col-2
		@include col-core;

		> * {
			@media #{$med} {
				@include col(2);
			}
		}

		// 2 col, but it aligns the content towards a center point from $med
		&-center {
			@include col-core;

			> * {
				@media #{$small-max} {
					// The 2-center is designed for a split CTA block with background-color,
					// we therefor need the same spacing check as used on blocks when the col turn vertically.
					@include spacing-check($padding: false);

					// Since the buttons/anchors are inline-block,
					// this is the best way to center them without using flex.
					text-align: center;
				}

				@media #{$med} {
					@include col(2);
					display: flex;

					&:not(:last-child) {
						text-align: right;
						justify-content: flex-end;
					}

					&:not(:first-child) {
						justify-content: flex-start;
					}

					// In case there's only one child – for som reason.
					&:only-child {
						justify-content: center;
						text-align: center;
					}
				}
			}
		}

		&-split {
			@include col-core($gutter: $t);

			> * {
				@media #{$med} {
					@include col(2);
					display: flex;

					&:not(:last-child) {
						justify-content: flex-start;
					}

					&:not(:first-child) {
						justify-content: flex-end;
						text-align: right;
					}

					// In case there's only one child – for som reason.
					&:only-child {
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
	}

	&-3 {
		@include col-core;

		> * {
			@media #{$med-large} {
				@include col(2);
			}

			@media #{$large} {
				@include col(3);
			}
		}
	}

	&-4 {
		@include col-core;

		> * {
			@media #{$med-large} {
				@include col(2);
			}

			@media #{$large} {
				@include col(4);
			}
		}
	}

	&-5 {
		// Since col-core() "resets" below $med, we've to flip the coin by
		// reversing the core col logic. $med-breakpoint will always trigger,
		// while in theory we'll never enter the $small-max-breakpoint styling.
		@include col-core($small-max-breakpoint: '(min-width: 999999999px)', $med-breakpoint: '(min-width: 0)');

		> * {
			@media #{$small-max} {
				@include col(2);
			}

			@media #{$med-large} {
				@include col(3);
			}

			@media #{$large} {
				@include col(5);
			}
		}
	}

	&-1-2 {
		@include col-core;

		> * {
			@media #{$med} {
				@include col(2, $width: false);

				&:first-child {
					width: 33.333333334%;
				}

				&:last-child {
					flex: 1;
					width: 66.66666667%;
				}
			}
		}
	}

	&-2-1 {
		@include col-core;

		> * {
			@media #{$med} {
				@include col(2, $width: false);

				&:first-child {
					flex: 1;
					width: 66.66666667%;
				}

				&:last-child {
					width: 33.333333334%;
				}
			}
		}
	}

	&-x {
		@include col-core;

		> * {
			@media #{$med} {
				flex: 1;
			}
		}
	}
}
