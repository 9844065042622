// EM
@function em($target, $context: 16) {
	@return ($target / $context) + em;
}

// LH
@function lh($target, $context: 16) {
	@return ($target / $context);
}

// REM
@function rem($target) {
	@return ($target / 16) + rem;
}

// Percentage
@function p($target, $context) {
	@return ($target / $context) * 100%;
}
