$logo-width: 80;
$logo-height: 57;
$menu-padding: $s;

.menu {
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba($dark, 0.6);
	transition: transform $time;

	+ .main {
		padding-top: rem($logo-height + $menu-padding * 2); // Total height of .menu
		// margin-bottom: rem($t);
	}

	// Bar overlay
	&:before {
		content: '';
		position: absolute;
		z-index: 11;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $dark;
		opacity: 0;
		transition: opacity $time;
	}

	&-open:not(.menu-transition):before {
		opacity: 1;
	}

	&-hide {
		transform: translateY(-100%);
	}

	// Both header and footer, unless stated othervise
	&-bar {
		display: flex;
		align-items: center;
		min-height: rem($logo-height); // Preserves the bar height based on logo height (42)

		// All items; anchor, btn and lang
		&-item {
			@include type('t6');
			position: relative; // In case of icons
			z-index: 12; // Higher priority than the overlay
			padding: rem((36 - 26) / 2) rem($t); // Math based on 't6' lh and intended height
			border-width: rem(2);
			border-style: solid;
			border-radius: rem($br-small);
			font-weight: $bold;
			white-space: nowrap; // Prevent text from breaking
			transition: color $time, border-color $time, opacity $time;

			&-text {
				display: block; // Make sure the lh is taken in consideration when height is calc

				// Check for a sibling (icon)
				&:first-child:not(:last-child) {
					padding-right: rem(26 + 5);
				}

				&:last-child:not(:first-child) {
					padding-left: rem(26 + 5);
				}
			}

			&-icon {
				position: absolute;
				top: 50%;
				margin-top: rem(-13);
				width: rem(26);
				height: rem(26);

				#menu-toggle & {
					&:before,
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						top: rem(11);
						margin-left: rem(-10);
						height: rem(4);
						width: rem(20);
						background-color: $bright;
						transition: transform $time;
					}

					.menu:not(.menu-open) & {
						&:before {
							transform: translateY(rem(-5));
						}

						&:after {
							transform: translateY(rem(5));
						}
					}

					.menu.menu-open & {
						&:before {
							transform: rotate(-45deg);
						}

						&:after {
							transform: rotate(45deg);
						}
					}
				}

				svg {
					fill: $dark;
					transition: fill $time;

					// Dev
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				// Anchor (icon is placed after text)
				&:first-child:not(:last-child) {
					left: rem(15);
				}

				// Button (icon is placed before text
				&:last-child:not(:first-child) {
					right: rem(10);
				}
			}

			@include root('a') {
				color: $prim;
				text-decoration: none;
			}

			@include root('button') {
				@include appearance;
				color: inherit; // For some reason the button tag does not inherit the body color properly
				cursor: pointer;
			}

			.menu-open &,
			.menu-transition & {
				&:not(#menu-toggle) {
					opacity: 0;
				}
			}
		}

		// All items except toggle and lang
		&-anchor {
			margin-right: auto; // Center pos
			margin-left: auto; // Center pos

			text-transform: uppercase;
			border-color: transparent;

			@media #{$small-max} {
				display: none;
			}

			&:hover {
				color: $bright;
			}
		}

		// Button look
		&-btn {
			background-color: transparent;
			border-color: $prim;

			&:hover {
				color: $prim;

				.menu-bar-item-icon {
					&:before,
					&:after {
						transform: translateY(0) rotate(0deg) !important;
						background-color: $prim !important;
					}
				}
			}
		}
	}

	&-body {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: $bright;
		background-color: $dark;
		transform: translateY(-100%);
		overflow-y: auto;
		will-change: transform;
		-webkit-overflow-scrolling: touch;
		padding: rem($menu-padding * 2 + $logo-height) 0;

		// @media #{$small-max} {
		// 	padding-bottom: rem($m);
		// }

		// @media #{$med-large} {
		// 	padding-bottom: rem($l);
		// }

		// @media #{$large} {
		// 	padding-bottom: rem($h);
		// }

		.menu-open & {
			transform: translateY(0);
			transition: transform $time linear;

			> * {
				opacity: 1;
				transform: translateY(0);
				transition: transform $time * 2 + $time/2 cubic-bezier(0.35, 0.66, 0.44, 0.99),
					opacity $time * 2 + $time/2 $time;
			}
		}

		.menu:not(.menu-open) & {
			transition: transform $time $time linear;

			> * {
				transform: translateY(-100%);
				opacity: 0;
				transition: transform $time * 2 $time cubic-bezier(0.35, 0.66, 0.44, 0.99), opacity $time ease-out;
			}
		}
	}

	&-anchor {
		@include type('t1');
		@include type-anchor($color: $bright, $border-color: $bright);
	}

	// &-list {
	// 	@include col-core;
	// 	@include list-reset;

	// 	// Nested list resets
	// 	ul {
	// 		@include list-reset;
	// 	}

	// 	// Header
	// 	.menu & {
	// 		@include width('l');
	// 	}

	// 	> * {
	// 		@media #{$med-large} {
	// 			@include col(2);
	// 		}

	// 		@media #{$large} {
	// 			@include col(3);
	// 		}
	// 	}

	// 	&-title {
	// 		&:not(:last-child) {
	// 			margin-bottom: rem($t);
	// 		}

	// 		> * {
	// 			margin-top: 0;
	// 			margin-bottom: 0;

	// 			.menu & {
	// 				@include type('t4');
	// 			}
	// 		}
	// 	}

	// 	&-item:not(:last-child) {
	// 		margin-bottom: rem($t);
	// 	}

	// 	&-anchor {
	// 		@include type('t5');

	// 		// Header
	// 		.menu & {
	// 			@include type-anchor($color: $bright, $border-color: $bright);
	// 		}
	// 	}
	// }

	&-logo {
		position: relative;
		z-index: 12;
		display: block;
		width: 100%;
		max-width: rem($logo-width);
		transition: opacity $time;

		&:hover {
			opacity: $opacity;
		}

		@media #{$small-max} {
			margin-right: auto;
		}
	}
}
