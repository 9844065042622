.before-enter {
	opacity: 0;
}

.entering {
	transition: opacity 100ms;
	opacity: 1;
}

.before-leave {
	transition: opacity 100ms;
}

.leaving {
	opacity: 0;
}
